import axiosInstance from './axiosInstance';
import axios from 'axios';
import Globals from "../core/globals";

const axiosInstanceNoToken = axios.create({
    baseURL: Globals.backendUri,
});

class ArtistService {
    constructor() {
        this.baseUri = '/artists/';
    }

    getArtistsForAuthenticatedUser() {
        return axiosInstance.get(`${this.baseUri}all`);
    }

    getArtistById(id) {
        return axiosInstance.get(`${this.baseUri}${id}`);
    }

    getArtistLinkByArtistId(id) {
        return axiosInstance.get(`${this.baseUri}link/artist/${id}`);
    }

    createArtist(artist) {
        return axiosInstance.post(`${this.baseUri}`, artist, {
            headers: {
                'Content-Type': 'multipart/form-data'},
        });    }

    updateArtist(artist) {
        return axiosInstance.put(`${this.baseUri}`, artist, {
            headers: {
                'Content-Type': 'multipart/form-data'},
        });    }

    searchArtists(searchString) {
        return axiosInstance.get(`${this.baseUri}search/remote`, {
            params: { searchString },
        });
    }

    getArtistLinkByIdentifier(identifier) {
        return axiosInstanceNoToken.get(`${this.baseUri}link/${identifier}`);
    }

    createArtistLink(artistLinkData) {
        return axiosInstance.post(`${this.baseUri}link`, artistLinkData);
    }

    editArtistLink(artistLinkData) {
        return axiosInstance.put(`${this.baseUri}link`, artistLinkData);
    }
}

export default new ArtistService();

